'use client';

import { useEffect, useState, type ReactNode } from 'react';

import posthogJs from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

interface PHProviderProps {
    children: ReactNode;
}

interface PostHogInitOptions {
    api_host: string;
    capture_pageview: boolean;
    capture_pageleave: boolean;
    persistence: 'localStorage';
    bootstrap: {
        distinctID: string;
        isIdentifiedID: boolean;
    };
    mask_all_text: boolean;
    respect_dnt: boolean;
    disable_session_recording: boolean;
    loaded: (ph: typeof posthogJs) => void;
}

export function PHProvider({ children }: PHProviderProps) {
    const [isInitialized, setIsInitialized] = useState<boolean | null>(null);

    useEffect(() => {
        const initPostHog = async () => {
            const key = process.env.NEXT_PUBLIC_POSTHOG_KEY;
            if (!key) {
                console.warn('PostHog API key is missing');
                setIsInitialized(false);
                return;
            }

            if (posthogJs.isFeatureEnabled('posthog-initialized')) {
                setIsInitialized(true);
                return;
            }

            try {
                const options: PostHogInitOptions = {
                    api_host:
                        process.env.NEXT_PUBLIC_APP_ENV === 'production'
                            ? '/ingest'
                            : process.env.NEXT_PUBLIC_POSTHOG_HOST,
                    capture_pageview: false,
                    capture_pageleave: true,
                    persistence: 'localStorage',
                    bootstrap: {
                        distinctID: 'anonymous',
                        isIdentifiedID: false,
                    },
                    mask_all_text: true,
                    respect_dnt: true,
                    disable_session_recording:
                        process.env.NEXT_PUBLIC_APP_ENV === 'development',
                    loaded: (ph) => {
                        if (navigator.doNotTrack === '1') {
                            ph.opt_out_capturing();
                        }
                        setIsInitialized(true);
                    },
                };

                posthogJs.init(key, options);
            } catch (error) {
                console.error('Failed to initialize PostHog:', error);
                setIsInitialized(false);
            }
        };

        if (typeof window !== 'undefined') {
            initPostHog();
        }
    }, []);

    if (isInitialized === null) return null;

    if (isInitialized === false) return children;

    return <PostHogProvider client={posthogJs}>{children}</PostHogProvider>;
}
