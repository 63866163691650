'use client';

import { Component, type ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export class AnalyticsErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    override async componentDidCatch(error: Error): Promise<void> {
        console.error('Analytics Error:', error);

        if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
            const Sentry = await import('@sentry/nextjs');
            Sentry.captureException(error, {
                extra: {
                    errorBoundaryName: 'AnalyticsErrorBoundary',
                },
            });
        }
    }

    override render() {
        if (this.state.hasError) {
            return this.props.children;
        }

        return this.props.children;
    }
}
