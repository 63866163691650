import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true,\"fallback\":[\"system-ui\",\"arial\"],\"adjustFontFallback\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/src/components/analytics/analytics-error-boundary.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/src/components/analytics/posthog-pageview.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/thumbgrab/thumbgrab/src/components/provider/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/home/runner/work/thumbgrab/thumbgrab/src/components/provider/translations-provider.tsx");
