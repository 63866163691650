'use client';

import { useEffect, useCallback } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { usePostHog } from 'posthog-js/react';

export function PostHogPageView() {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const posthog = usePostHog();

    const handlePageView = useCallback(() => {
        if (typeof window === 'undefined' || !pathname || !posthog) return;

        const url =
            window.origin +
            pathname +
            (searchParams?.toString() ? `?${searchParams.toString()}` : '');
        posthog.capture('$pageview', { $current_url: url });
    }, [pathname, searchParams, posthog]);

    useEffect(() => {
        const timeoutId = setTimeout(handlePageView, 100);
        return () => clearTimeout(timeoutId);
    }, [handlePageView]);

    return null;
}
