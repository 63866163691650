'use client';

import { createContext, useContext } from 'react';

import type { Translations } from '@/types';

const TranslationsContext = createContext<Translations | null>(null);

export function TranslationsProvider({
    children,
    translations,
}: {
    children: React.ReactNode;
    translations: Translations;
}) {
    return (
        <TranslationsContext.Provider value={translations}>
            {children}
        </TranslationsContext.Provider>
    );
}

export function useTranslations() {
    const translations = useContext(TranslationsContext);
    if (!translations) {
        throw new Error(
            'useTranslations must be used within TranslationsProvider'
        );
    }
    return translations;
}
